.custom-dropdown {
  border-radius: 50px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.1);
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  height: 47px;
}

.custom-dropdown:hover {
  background-color: transparent;
  color: white;
  box-shadow: none;
  border: 2px solid rgba(255, 255, 255, 0.1);
}

.custom-dropdown:focus,
.custom-dropdown:active,
.custom-dropdown:hover {
  background-color: transparent !important;
  color: white;
  box-shadow: none;
  outline: none;
  border: 2px solid rgba(255, 255, 255, 0.1) !important;

}

.custom-dropdown-menu {
  background-color: none;
}

.p {
  color: black;
}

.h2 {
  color: black;
}


.avb{
  box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10px 18px;
gap: 10px;

width: 133px;
height: 44px;

border: 1px solid #1BEEBB;
border-radius: 50px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}