.preload{
    text-align: center;
    margin-top: 316px;
    /* animation: slide-in 2s ease-in-out infinite alternate; */
    /* animation: zoom-out 2s ease-in-out infinite alternate; */
    animation: blink-zoom 2.5s ease-in-out infinite;
    transform-origin: center center;
}

/* @keyframes slide-in {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  } */


  @keyframes zoom-out {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0);
    }
  }



  @keyframes blink-zoom {
    0%, 50% {
      transform: scale(1);
    }
    25%, 75% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }